/* Navbar Styles */
.navbar {
  font-family: 'Inter', sans-serif; /* Change to your desired font */
  font-weight: 700; /* Normal weight */
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.51); /* Transparent fallback */
  height: 3.75rem; /* 60px */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem; /* 20px */
  transition: transform 0.4s ease, background 0.3s ease;
  will-change: transform;
  -webkit-backface-visibility: hidden; /* Fix for Safari flickering */
  backface-visibility: hidden;
}

.navbar-space {
  height: 1.875rem; /* Adjust to match the height of your navbar */
  background-color: #f8f9fa; /* To make sure it's not visible */
}

/* Navbar container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 75rem; /* 1200px */
}

/* Logo styling */
.navbar-logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-decoration: none;
  font-size: 2rem;
}

/* Logo image */
.navbar-logo-img {
  height: 2rem; /* 32px */
  width: auto;
}

/* Mobile menu icon */
.menu-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 1.25rem; /* 20px */
  transform: translateY(-50%);
  z-index: 1000;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Navigation menu */
.nav-menu {
  display: flex;
  list-style: none;
  margin-left: auto;
  border-radius: 12px;
  text-align: center;
  opacity: 1;
}

/* Navigation links */
.nav-links {
  font-family: 'Inter', sans-serif; /* Change to your desired font */
  font-weight: 700; /* Normal weight */
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.nav-links:hover {
  color: #252525;
  background-color: rgba(255, 255, 255, 0); /* Highlight effect */
  border-radius: 8px;
}

/* Hidden state for the navbar */
.navbar-hidden {
  transform: translateY(-100%);
}

html,
body {
  overscroll-behavior: contain; /* Prevent rubber-banding effects */
  background-color: #f8f9fa;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 960px) {
  .navbar-logo {
    z-index: 1000;
  }

  .menu-icon {
    display: block;
    font-size: 2rem;
  }
  
  .menu-icon.active {
    transform: translateY(-50%) rotate(90deg);
    font-size: 2.5rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.75rem; /* Align below navbar */
    right: 0;
    width: 15.625rem; /* 250px */
    background: rgba(208, 208, 208, 0.9);
    align-items: center;
    justify-content: center;
    padding: 1.25rem 0; /* 20px */
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }
  
  .nav-menu.active {
    opacity: 1;
    pointer-events: all;
  }

  .navbar::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); /* Safari support */
    pointer-events: none;
    transition: backdrop-filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0;
  }
  
  .navbar.menu-active::before {
    opacity: 1;
  }

  .nav-links {
    line-height: 3.75rem; /* 60px */
    font-size: 1.8rem;
    width: 80%;
    text-align: center;
    color: #0e0e0e;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
  }

  .nav-links:hover {
    color: #7c7c7c;
    border-radius: 9px;
  }

  .nav-menu.active .nav-links {
    margin: 0.625rem 0; /* 10px */
  }
}

.services-container {
    padding: 40px;
    background-color: #f8f9fa;
    text-align: center;
}

.services-header {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
    text-transform: uppercase;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.service-card {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 280px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    position: relative;
}

.service-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
}

.service-card h2 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.service-card p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}

.service-card ul {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
}

.service-card ul li {
    font-size: 1rem;
    color: #555;
    padding-left: 1.5rem;
    position: relative;
}

.service-card ul li::before {
    content: "•";
    position: absolute;
    left: 0;
    color: #4CAF50;
    font-size: 1.2rem;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .services-list {
        flex-direction: column;
        align-items: center;
    }
    .service-card {
        width: 90%;
        margin-bottom: 20px;
    }
}

/* Main container styling */
.contact-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Inter Tight', sans-serif;
    margin-bottom: 50px;  /* Add space between the card and footer */
}
.contact-section {
    background-color: #f8f9fa; /* Light gray background */
    padding: 10px; /* Optional padding for spacing */
}

.contact-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'Inter Tight', sans-serif;
    text-align: center;
}

.contact-description {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 30px;
    max-width: 600px;
    font-family: 'Inter Tight', sans-serif;
    margin: 0 auto 30px;
    text-align: center;
}

/* Wrapper for form and contact info */
.contact-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px; /* Add some space between form and info */
}

/* Left side - Form container */
.contact-form-container {
    flex: 1;
    padding-right: 20px; /* Space between form and info */
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.form-group label {
    font-size: 1.1rem;
    color: #444;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    margin-top: 5px;
}

.form-group textarea {
    height: 150px;
    resize: vertical;
}

.submit-btn {
    background-color: #007bff;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.submit-btn:active {
    background-color: #004085;
}

/* Right side - Contact info container */
.contact-info-container {
    flex: 1;
    padding-left: 20px; /* Space between form and info */
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info-item {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 15px;
}

.info-item i {
    margin-right: 15px;
    font-size: 0.1rem; /* Adjust size for desktop */
    color: #007bff; /* Icon color */
}

.info-text {
    margin: 0;
    color: inherit; /* Inherit color from the parent */
    text-decoration: none; /* No underline */
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .contact-wrapper {
        flex-direction: column;
        gap: 20px; /* Reduce gap between form and info */
    }

    .contact-form-container,
    .contact-info-container {
        flex: 1;
        padding: 0; /* Remove padding for better fit */
    }

    .contact-title {
        font-size: 2rem; /* Adjust title size */
    }

    .contact-description {
        font-size: 1rem; /* Adjust description size */
        margin-bottom: 20px; /* Reduce margin */
    }

    .info-item {
        font-size: 0.8rem; /* Adjust font size for mobile */
    }

    .info-icon {
        font-size: 1.25rem; /* Adjust icon size */
    }

    .form-group label {
        font-size: 1rem; /* Adjust label size */
    }

    .form-group input,
    .form-group textarea {
        font-size: 0.9rem; /* Adjust input/textarea font size */
    }

    .submit-btn {
        font-size: 1rem; /* Adjust button size */
        padding: 10px; /* Adjust padding */
    }
}

@media (max-width: 480px) {
    .contact-title {
        font-size: 1.5rem; /* Further reduce title size */
    }

    .contact-description {
        font-size: 0.9rem; /* Further reduce description size */
    }

    .form-group label {
        font-size: 0.9rem; /* Further reduce label size */
    }

    .form-group input,
    .form-group textarea {
        font-size: 0.8rem; /* Further reduce input/textarea font size */
    }

    .info-item {
        font-size: 0.9rem; /* Further adjust font size for mobile */
    }

    .info-icon {
        font-size: 1.2rem; /* Further reduce icon size */
    }
}

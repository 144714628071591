/* General Page Styles */
.about-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa; /* Light background for contrast */
    overflow: hidden;
    font-family: 'Inter Tight', sans-serif;
}

/* Scattered Images */
.scattered-image {
    position: absolute;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.8;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.scattered-image:hover {
    transform: scale(1.1);
    opacity: 1;
}

.top-left {
    top: 10%;
    left: 5%;
    width: 150px;
}

.top-right {
    top: 15%;
    right: 8%;
    width: 200px;
}

.bottom-left {
    bottom: 12%;
    left: 7%;
    width: 120px;
}

.bottom-right {
    bottom: 8%;
    right: 10%;
    width: 180px;
}

/* About Card */
.about-card {
    background: #ffffff;
    padding: 40px 30px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    max-width: 600px;
    text-align: center;
    z-index: 2;
}

.about-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.about-text {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 15px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .top-left, .top-right, .bottom-left, .bottom-right {
        display: none; /* Hide scattered images on small screens */
    }

    .about-card {
        padding: 20px 15px;
        max-width: 90%;
    }

    .about-title {
        font-size: 2rem;
    }

    .about-text {
        font-size: 1rem;
    }
}

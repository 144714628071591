.cards {
    padding: 4rem 1rem; /* Reduce padding for smaller screens */
    background: #f8f9fa;
}

h1 {
    text-align: center;
    font-size: 1.8rem; /* Adjust font size for smaller screens */
}

.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
    margin: 30px 0; /* Reduce margin for smaller screens */
}

.cards__items {
    margin-bottom: 24px;
    list-style-type: none;
    padding: 0;
    display: flex; /* Ensure cards are displayed in a row */
    flex-wrap: wrap; /* Allow wrapping of cards */
    justify-content: center; /* Center the cards */
}

.cards__item {
    margin: 1rem;
    background-color: #88888824;  /* Card background */
    border-radius: 15px;  /* Curved edges */
    width: 320px;  /* Default card width */
    height: 380px;  /* Default card height */
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);  /* Shadow effect */
    overflow: hidden;  /* Prevent content from spilling out */
    position: relative;
    transition: transform 0.2s ease-in-out; /* Smooth transition on hover */
}

.cards__item:hover {
    transform: translateY(-10px); /* Card lift effect on hover */
}

.cards__item__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;  /* Ensure the link fills the card */
    text-decoration: none;  /* Remove underline */
}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;  /* Aspect ratio for the image */
    overflow: hidden;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out; /* Smooth scale effect */
}

.cards__item__img:hover {
    transform: scale(1.1); /* Slight zoom effect on image hover */
}

.cards__item__info {
    padding: 15px; /* Adjust padding for text */
    text-align: center;
}

.cards__item__text {
    color: #252e48;
    font-size: 14px; /* Slightly smaller font size */
    line-height: 1.4;
}

/* Tablet View */
@media only screen and (max-width: 1024px) {
    .cards__item {
        width: 240px; /* Smaller card width for tablets */
        height: 340px; /* Adjust card height for tablets */
    }

    .cards__item__pic-wrap {
        padding-top: 120%; /* Reduce the height of the image area for mobile */
    }

    .cards__item__img {
        object-fit: cover; /* Ensure the image scales proportionally */
    }
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .cards {
        padding: 2rem 0.5rem; /* Reduce overall padding for mobile */
    }

    .cards__item {
        width: 200px;  /* Reduce card size further for mobile */
        height: 300px; /* Adjust card height */
        margin: 0.5rem; /* Reduce margin between cards */
    }

    .cards__item__info {
        padding: 20px; /* Further reduce padding inside the card */
    }

    h1 {
        font-size: 1.5rem; /* Reduce the size of the heading */
    }
}

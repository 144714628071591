/* General styles for the home page */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f0f8ff; /* Light blue background */
  padding: 20px;
  box-sizing: border-box;
}

/* Styling for HeroSection */
.hero-section {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

/* Styling for Cards */
.cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.card {
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform, box-shadow;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.card p {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}

/* Styling for Home About Us Section */
.home-about-us {
  display: flex;
  flex-direction: row; /* Positions the text on the left and the image on the right */
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

/* Styling for the text */
.home-about-text {
  flex: 1;
  color: #2c2c2c;
  max-width: 50%; /* Limits the text section to half the width */
  padding: 20px;
  text-align: left;
  font-family: 'Inter Tight', sans-serif; /* Custom font */
}

.home-about-text h2 {
  color: #2c2c2c;
  font-size: 2.5rem;
  text-align: left; /* Default alignment for larger screens */
  margin-bottom: 15px;
  font-family: 'Inter Tight', sans-serif; /* Custom font */
}

/* Styling for the image */
.home-about-image {
  padding: 20px;
  flex: 1;
  max-width: 50%; /* Limits the image section to half the width */
  display: flex;
  justify-content: center; /* Centers the image horizontally */
}

.home-about-image img {
  max-width: 50%; /* Adjusts image width */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .cards {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
  }

  .card {
      width: calc(100% - 40px);
      max-width: 90%;
      margin: 10px 0;
  }

  .home-about-us {
      flex-direction: column; /* Stacks the content vertically */
      text-align: center;
  }

  .home-about-text, .home-about-image {
      max-width: 100%; /* Full width on smaller screens */
      text-align: center; /* Center the h2 text on mobile */
  }

  .home-about-text h2 {
    text-align: center; /* Center the h2 text on mobile */
    font-size: 2rem; /* Adjust font size for smaller screens */
    margin-bottom: 20px;
  }

  .home-about-text p {
      font-size: 1rem;
  }
}

/* iOS Specific Adjustments */
@supports (-webkit-touch-callout: none) {
  .card {
      padding: 20px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  }

  .card:hover {
      transform: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

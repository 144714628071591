/* General Reset */
body {
    line-height: 1.5;
    font-family: inherit; /* Use the website's default font */
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Container and Layout */
  .container {
    max-width: 1170px;
    margin: auto;
  }
  
  .row {
    display: flex;
    justify-content: center; /* Center align columns */
    flex-wrap: wrap;
  }
  
  ul {
    list-style: none;
  }
  
  /* Footer Styling */
  /* General Footer Styling */
.footer {
  background-color: #000000;
  padding: 30px 0;
  color: #ffffff; /* Ensure text is visible */
}

.container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  justify-content: space-between; /* Ensure sections are spaced out */
  flex-wrap: wrap;
}

.footer-col {
  flex: 1; /* Flex to fill space */
  padding: 0 15px;
}

.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-col ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #bbbbbb;
}

.footer-col ul li a {
  color: #bbbbbb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}

/* Footer Bottom Section */
.footer-bottom {
  display: flex;
  justify-content: space-between; /* Pushes content to both sides */
  align-items: center; /* Aligns vertically */
  margin-top: 40px;
  border-top: 1px solid #bbbbbb;
  padding-top: 20px;
  font-size: 12px; /* Adjust the copyright text font size */
}

.footer-logo-img {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.made-by {
  text-align: right; /* Aligns "Made by" to the right */
  font-size: 12px; /* Adjust 'Made by' section font size */
}

.made-by-img {
  width: 50px;
  height: auto;
  vertical-align: middle;
  margin-left: 5px;
}

/* Responsive Design */
@media (max-width: 767px) {

  .footer-logo-img {
    display: block;
    margin: 0 auto; /* Center-aligns the logo */
    margin-bottom: 20px;
  }

  .footer-col {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .footer-col h4 {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    margin: 0 auto; /* Center the entire element */
    margin-bottom: 10px;
  }

  .footer-col h4 i {
    margin-right: 1px; /* Adds space between the icon and the text */
  }

  .footer-bottom {
    flex-direction: column; /* Stacks content vertically */
    align-items: center; /* Center aligns for mobile */
  }

  .footer-copyright {
    text-align: center; /* Center aligns content */
  }

  .made-by {
    text-align: center; /* Center "Made by" */
  }
}

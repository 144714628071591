/* Global Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  /* General Section Styling */
  .home,
  .about,
  .services,
  .contact {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    background-color: #333; /* Placeholder background color */
    color: #720000;
  }
  
  /* Responsive Text */
  .home, .about, .services, .contact {
    font-size: calc(1.5rem + 1vw);
  }
  
  /* Text Shadow for Readability */
  .home, .about, .services, .contact {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  /* Responsive Design for Small Screens */
  @media screen and (max-width: 960px) {
    .home, .about, .services, .contact {
      font-size: calc(1.2rem + 1vw);
      padding: 10px;
      text-align: center;
    }
  }
  
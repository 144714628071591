:root{
    --primary: #ffffff;
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;  /* Removes underline from the link */
}

.btn--primary{
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-radius: 45px; /* Adjust the value as needed */
}

.btn--medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding: 12px 21px;
    font-size: 20px;
}
.btn--medium:hover, .btn--large:hover{
    background: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
    text-decoration: none;  /* Ensures underline is removed on hover/focus as well */
}
video {
    object-fit: cover; /* Ensures the video scales proportionally */
    width: 100%;
    height: 100%;
    position: absolute; /* Places the video at the container's boundaries */
    top: 0;
    left: 0;
    z-index: -1; /* Keeps the video behind the navbar and other content */
    pointer-events: none; /* Prevents interactions with the video */
    background: #000; /* Fallback color in case the video doesn't load */
    filter: brightness(50%); /* Reduces brightness to make the video darker */
  }

 .hero-container {
/*background: url('video/video.mp4') center center/cover no-repeat;*/
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  position: relative; /* Stacks the content and ensures correct layering */
  overflow: hidden; /* Prevents any content from spilling out */
  text-align: center; /* Centers text alignment */
  color: #fff; /* Ensures text remains readable over the video */
  z-index: 1; /* Keeps hero section content above the video but below the navbar */
  padding-top: 80px; /* Adjusts for the navbar height */
 }

 .hero-container > h1 {
    color: #fff;
    font-size: 50px;
    margin-top: -100px;
 }
 .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 22px;
    font-family: 'Inter Tight', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
 }

 .hero-btns{
    margin-top: 32px;
    text-decoration: none;  /* Removes the underline */
 }

 @media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 38px;
        margin-top: -150px;
    }
    
 }

 @media screen and (max-width: 768px) {
    .hero-container > h1 {
        font-size: 28px;
        margin-top: -100px;
    }

    .hero-container > p {
        font-size: 17px;

    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
        text-decoration: none;  /* Removes the underline */
    }
 }
